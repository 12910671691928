import React, { Component, Suspense, lazy } from 'react'
import { Provider } from 'mobx-react'
import { Router } from 'react-router-mobx'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Helmet from 'react-helmet'
// import _get from 'lodash/get'
// import { parse } from 'querystring'
import { CookiesProvider } from 'react-cookie'
// import { Layout, message, Spin, Modal, Icon, BackTop } from 'antd'
import { Layout, Spin, Modal, Icon, BackTop } from 'antd'
import { Capacitor, Plugins} from '@capacitor/core'
import { App as CApp } from '@capacitor/app'
import ConnectedLocaleProvider from './providers/locale'
import ConnectedIntlProvider from './providers/intl'
// import Error404 from './Error404'
import Header from '../common/Header'
import Footer from '../common/Footer'
import Navigation from './Navigation'
import { ThemeProvider } from './ThemeProvider'
import platformObj from '~/config'
import AppUrlListener from "./utils/AppUrlListener"

// import HomePage from './HomePage'
// import EventListPage from './EventListPage'
// import EventPage from './EventPage'
// import Pricing from './Pricing'
// import EventForm from './pages/EventForm'
// import EventFormSuccess from './pages/EventForm/Success'
// import EventRegisterPage from './EventRegisterPage'
// import EventRegisterPage from './pages/EventRegister'
// import EventRegistration from './pages/EventRegistration'
// import RegistrationOrderEdit from './pages/RegistrationOrderEdit'
// import OrderPayment from './pages/OrderPayment'
// import Order from './pages/Order'
// import RegistrationPage from './RegistrationPage'
// import RegistrationConfirmPage from './RegistrationConfirmPage'
// import RegistrationEditPage from './RegistrationEditPage'
// import PaymentPage from './PaymentPage'
// import DashboardPage from './DashboardPage'
// import LinepayConfirmHook from './LinepayConfirmHook'
// import ProfilePage from './ProfilePage'
// import ProfileCardsPage from './ProfileCardsPage'
// import EditProfilePage from './EditProfilePage'
// import ResetPassword from './ResetPassword'
// import ResetPasswordInput from './ResetPasswordInput'
import i18nStore from './stores/i18n'
import routerStore from './stores/router'
import withUser from '~/App/hocs/withUser'
import withTracker from '~/App/hocs/withTracker'
import { AppWrapper } from '~/styles'
import { detect } from 'detect-browser'
import Loading from './pages/Loading'
import Error404 from './pages/Error/404'
import ChangeEmail from './pages/ChangeEmail'
import './index.css'
import LoginPage from './pages/LoginPage/LoginPage'
import RegisterationPage from './pages/LoginPage/RegisterationPage'
// import SignInLinkPage from './pages/LoginPage/SignInLinkPage'
import SignInEmailPage from './pages/LoginPage/SignInEmailPage'
import ResetPasswordPage from './pages/LoginPage/ResetPasswordPage'
import { AuthContext } from '../Context/AuthContext'

// import EventRegistration from './pages/EventRegistration'
const EventRegistration = lazy(() => import('./pages/EventRegistration'))
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'))
const EventListPage = lazy(() => import('./EventListPage'))
// const TestPage = lazy(() => import('./pages/TestPage'))
// const searchEvent = lazy(() => import('../common/Header/searchEvent'))
const EventPage = lazy(() => import('./EventPage'))
const Pricing = lazy(() => import('./Pricing'))
const EventForm = lazy(() => import('./pages/EventForm'))
const RedirectHref = lazy(() => import('./pages/RedirectHref'))
const EventFormSuccess = lazy(() => import('./pages/EventForm/Success'))
const RegistrationOrderEdit = lazy(() =>
  import('./pages/RegistrationOrderEdit')
)
const OrderPayment = lazy(() => import('./pages/OrderPayment'))
const Order = lazy(() => import('./pages/Order'))
const DashboardPage = lazy(() => import('./DashboardPage'))
const ProfilePage = lazy(() => import('./ProfilePage'))
const ProfileCardsPage = lazy(() => import('./ProfileCardsPage'))
const EditProfilePage = lazy(() => import('./EditProfilePage'))
const ResetPassword = lazy(() => import('./ResetPassword'))
const ResetPasswordInput = lazy(() => import('./ResetPasswordInput'))

const detectedBrowser = detect()
const browser = {
  isUnsupported: detectedBrowser && detectedBrowser.name === 'ie',
  error: {
    title: [
      <Icon
        type="exclamation-circle"
        key="icon-exclamation-circle"
        style={{ color: 'red', fontSize: '21px' }}
      />,
      `  ไม่สามารถใช้งานได้บน IE Browser`
    ],
    content: (
      <div style={{ fontSize: '21px' }}>
        <a
          href="https://www.google.com/intl/th_ALL/chrome/"
          style={{ textDecoration: 'underline' }}>
          ดาวน์โหลด Chrome Browser
        </a>
        <br />
        หรือ <br />
        <a
          href="https://www.mozilla.org/th/firefox/new/"
          style={{ textDecoration: 'underline' }}>
          ดาวน์โหลด Firefox Browser
        </a>
      </div>
    )
  }
}
const stores = {
  i18n: i18nStore,
  router: routerStore
}

@withUser({ refetch: false })
class PrivateRoute extends Component {
  static contextType = AuthContext
  // componentWillReceiveProps(nextProps) {
  //   // eslint-disable-line
  //   const { currentUser, currentUserLoading, onAuth, location } = nextProps
  //   if (!currentUser && !currentUserLoading) {
  //     message.info('Please login or register', 3)
  //     onAuth({
  //       redirectTo: `${process.env.REACT_APP_BASE_URI}${location.pathname}${location.search}`
  //     })
  //   }
  // }
  // componentWillMount() {
  //   // eslint-disable-line
  //   const { currentUser, currentUserLoading, onAuth, location } = this.props
  //   if (!currentUser && !currentUserLoading) {
  //     message.info('Please login or register', 3)
  //     onAuth({
  //       redirectTo: `${process.env.REACT_APP_BASE_URI}${location.pathname}${location.search}`
  //     })
  //   }
  // }
  render() {
    const {
      component: Component,
      render,
      currentUser,
      currentUserLoading,
      onAuth,
      history,
      ...rest
    } = this.props
    // console.log(this.context.userIsLoading, this.context.user , currentUser , currentUserLoading);
    return (
      <Route
        {...rest}
        render={(props) => {
          if (currentUser) {
            return Component ? <Component {...props} /> : render(props)
          }
          if (this.context.userIsLoading  || currentUserLoading ) {
          // if (currentUserLoading) {
            return (
              <div className="modLoadding">
                <Spin tip="Loading..." size="large" />
              </div>
            )
          }
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
        }}
      />
    )
  }
}

// @withRouter
@withUser()
// @observer
class App extends Component {
  state = {
    showAuthModal: false,
    redirectTo: null,
    
  }

  // UNSAFE_componentWillMount () {
  //   const location = parse(window.location.search.slice(1))
  // }

  showAuthModal = ({ redirectTo }) => {
    // e.preventDefault()
    this.setState({
      showAuthModal: true,
      redirectTo
    })
  }

  hideAuthModal = () => {
    this.setState({
      showAuthModal: false,
      redirectTo: null
    })
  }

  handleAuth = ({ redirectTo }) => {
    this.showAuthModal({ redirectTo })
  }

  render() {
    if (this.props.currentUserLoading) {
      return (
        <div
          style={{
            position: 'absolute',
            top: '20%',
            left: '50%',
            margin: 'auto',
            padding: '22px',
            borderRadius: '20px',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgb(255,255,255,0.3)'
          }}>
          <Spin tip="Loading..." size="large" />
        </div>
      )
    }
    if (browser.isUnsupported) {
      return (
        <Modal visible={true} footer={null} title={browser.error.title}>
          {browser.error.content}
        </Modal>
      )
    }

    const {StatusBar } = Plugins
    // const UA = navigator.userAgent || navigator.vendor || window.opera
    // const isLINE = UA.indexOf("Line/") > -1
    // if(isLINE) {
    //   alert(UA)
    //   console.log('UA', UA.search(" Chrom"), UA)
    // }
    // let isLineUser = false
    // if(this.props.currentUser && this.props.currentUser.email.search('raceline+') > -1 && this.props.currentUser.email.search('@thai.run') > -1) {
    //   if(isLINE) {
    //     isLineUser = true
    //   }
    // }
    // if(this.props.currentUserLoading) return <div></div>
    const isNative = Capacitor.isNativePlatform()
    const osNative = Capacitor.getPlatform()

    CApp.addListener('backButton', () => {
      window.history.back()
    })

    const statusInfo = async()=>{
      StatusBar.setBackgroundColor({color:"#FFFFFF"})
      StatusBar.setOverlaysWebView({overlay:false})
      StatusBar.setStyle({style:"LIGHT"})
    }
    if(isNative)statusInfo()
    
    return (
      <AppWrapper>
        <Helmet>
          <title>
            {platformObj.title}
          </title>
          <meta
            name="viewport"
            content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <meta
            name="description"
            content={platformObj.metaDescription}
          />
        </Helmet>
        <CookiesProvider>
          <Provider {...stores}>
            <ConnectedLocaleProvider>
              <ConnectedIntlProvider>
                <ThemeProvider>
                <Router component={BrowserRouter} routerStore={routerStore}>
                  <Switch>
                    <Route exact path="/reg/:regId/payatall" />
                    <Route
                      render={() => (
                        <Layout
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                          <Layout.Header
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: isNative? 'end':"center",
                              backgroundColor: '#fff',
                              borderBottom: '1px solid #dddfe2',
                              maxWidth: '1250px',
                              height:isNative&&osNative==="ios"&&"94px"
                            }}>
                            {/* <Layout.Header style={{ display: isLineUser ? 'none' : 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#1c81c4', borderBottom: 'unset', maxWidth: '1250px' }}> */}
                            <Header
                              // onAuthClick={this.showAuthModal}
                              currentUser={this.props.currentUser}
                            />
                          </Layout.Header>
                          <Layout.Content
                            style={ !isNative ? {
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'flex-start',
                              maxWidth: '1250px',
                              width: '100%',
                              background: 'white'
                            } : {
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'flex-start',
                              maxWidth: '1250px',
                              width: '100%',
                              background: 'white',
                              marginBottom : '65px'
                            } }>
                            <Suspense fallback={<div>Loading...</div>}>
                            <AppUrlListener/>
                              <Switch>
                                <Route
                                  exact
                                  path="/"
                                  component={withTracker(EventListPage)}
                                />
                                <Route
                                  exact
                                  path="/login"
                                  component={withTracker(LoginPage)}
                                />
                                <Route
                                  exact
                                  path="/registeration/:token"
                                  component={withTracker(RegisterationPage)}
                                />
                                <Route
                                  exact
                                  path="/signin/:token"
                                  component={withTracker(SignInEmailPage)}
                                />
                                <Route
                                  exact
                                  path="/resetpassword"
                                  component={withTracker(ResetPasswordPage)}
                                />
                                <Route
                                  exact
                                  path="/privacy-policy"
                                  component={withTracker(PrivacyPolicy)}
                                />
                                <Route
                                  exact
                                  path="/loading"
                                  component={Loading}
                                />
                                {/* <Route exact path="/eventSearch/:eventName/:provinces/:startDate/:startDateEnd/:distance" component={withTracker(searchEvent)} /> */}
                                {/* <Route exact path="/" component={withTracker(searchEvent)} /> */}
                                <Route
                                  exact
                                  path="/events"
                                  component={withTracker(EventListPage)}
                                />
                                <Route
                                  exact
                                  path="/events/:eventSlug"
                                  component={withTracker(EventPage)}
                                />
                                <Route
                                  exact
                                  path="/password/reset"
                                  component={withTracker(ResetPasswordInput)}
                                />
                                <Route
                                  exact
                                  path="/reset/:resetToken"
                                  component={withTracker(ResetPassword)}
                                />
                                <Route
                                  exact
                                  path="/resetEmail/:resetToken"
                                  component={withTracker(ChangeEmail)}
                                />
                                <Route
                                  exact
                                  path="/pricing"
                                  component={withTracker(Pricing)}
                                />
                                <Route
                                  exact
                                  path="/eventForm"
                                  component={withTracker(EventForm)}
                                />
                                <Route
                                  exact
                                  path="/redirectHref"
                                  component={withTracker(RedirectHref)}
                                />
                                {/* <Route exact path="/testPage" component={withTracker(TestPage)} /> */}
                                <Route
                                  exact
                                  path="/eventForm/success"
                                  component={withTracker(EventFormSuccess)}
                                />
                                <PrivateRoute
                                  exact
                                  path="/profile"
                                  component={withTracker(ProfilePage)}
                                  onAuth={this.handleAuth}
                                />
                                <PrivateRoute
                                  exact
                                  path="/profile/cards"
                                  component={withTracker(ProfileCardsPage)}
                                  onAuth={this.handleAuth}
                                />
                                <PrivateRoute
                                  exact
                                  path="/profile/edit"
                                  component={withTracker(EditProfilePage)}
                                  onAuth={this.handleAuth}
                                />
                                <PrivateRoute
                                  exact
                                  path="/:eventSlug/register"
                                  component={withTracker(EventRegistration)}
                                  onAuth={this.handleAuth}
                                />
                                <PrivateRoute
                                  exact
                                  path="/:eventSlug/private"
                                  component={withTracker(EventRegistration)}
                                  onAuth={this.handleAuth}
                                />
                                <PrivateRoute
                                  exact
                                  path="/events/:eventSlug/register"
                                  component={withTracker(EventRegistration)}
                                  onAuth={this.handleAuth}
                                />
                                <PrivateRoute
                                  exact
                                  path="/events/:eventSlug/private"
                                  component={withTracker(EventRegistration)}
                                  onAuth={this.handleAuth}
                                />
                                <PrivateRoute
                                  exact
                                  path="/orders/:id/edit"
                                  component={withTracker(RegistrationOrderEdit)}
                                  onAuth={this.handleAuth}
                                />
                                <PrivateRoute
                                  exact
                                  path="/orders/:id/pay"
                                  component={withTracker(OrderPayment)}
                                  onAuth={this.handleAuth}
                                />
                                <PrivateRoute
                                  exact
                                  path="/orders/:id"
                                  component={withTracker(Order)}
                                  onAuth={this.handleAuth}
                                />
                                <PrivateRoute
                                  path="/reg/:id"
                                  component={withTracker(EventRegistration)}
                                  onAuth={this.handleAuth}
                                />

                                <PrivateRoute
                                  path="/dashboard"
                                  component={withTracker(DashboardPage)}
                                  onAuth={this.handleAuth}
                                />
                                {/* <PrivateRoute exact path="/dashboard/*" component={Error404} /> */}
                                <Route
                                  exact
                                  path="/:eventSlug"
                                  component={withTracker(EventPage)}
                                />
                                <Route
                                  exact
                                  path="/:eventSlug/*"
                                  component={Error404}
                                />
                              </Switch>
                            </Suspense>
                            { !isNative && <BackTop>
                              <div
                                style={{
                                  height: 40,
                                  width: 40,
                                  lineHeight: '40px',
                                  borderRadius: 4,
                                  backgroundColor: '#1088e9',
                                  color: '#fff',
                                  textAlign: 'center',
                                  fontSize: 14,
                                  marginBottom : '50px'
                                }}>
                                ^
                              </div>
                            </BackTop>}
                          </Layout.Content>
                          { ( process.env.REACT_APP_PLATFORM === 'thai' || isNative ) &&
                          <Layout.Footer style={isNative? {padding: 'unset', width: '100%', maxWidth: '1250px', position : 'fixed' , bottom : '0',zIndex : '999'} :{ padding: 'unset', width: '100%', maxWidth: '1250px'}}>
                            {/* <DemoBlock title='徽标' padding='0'> */}
                              { isNative ? <Navigation /> : <Footer />  }
                            {/* </DemoBlock> */}
                          </Layout.Footer>
                          }
                        </Layout>
                      )}
                    />
                  </Switch>
                </Router>
                </ThemeProvider>
              </ConnectedIntlProvider>
            </ConnectedLocaleProvider>
          </Provider>
        </CookiesProvider>
      </AppWrapper>
    )
  }
}

// TODO: Refactor auth logic decouple state and actions
export default App