import React, { useEffect, useState } from 'react';
import { Form, Icon, Button , Checkbox, Divider, Radio } from 'antd';
import { compose } from "recompose"
import { withApollo } from "@apollo/client/react/hoc";
import { withRouter , Link} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  TextField,
  FormControl,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import countries from "./../CountryInformations"

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

function LoginForm(props) {

  const { onRequestOtp,
          handleFacebookLogin , 
          loading , 
          initialPhoneNumber , 
          setInitialPhoneNumber, 
          acceptPolicy,
          initialCode,
          setInitialCode } = props;
  const [tab , setTab] = useState(0)
  const [code, setCode] = useState(initialCode);
  const [phoneNumber, setPhoneNumber] = useState( initialPhoneNumber || null );
  const [phoneNumberError, setPhoneNumberError] = useState(false)
  const [email, setEmail] = useState(null)
  const [emailError, setEmailError] = useState(false)
  const [password , setPassword] =useState(null)
  const [passwordError , setPasswordError] = useState(false);
  const [showPassword , setShowPassword] = useState(false)

  const otpWhatsAppEnabled = (process.env.REACT_APP_PLATFORM === "maybank" && ['ID'].includes(code.code));
  const [otpMethod, setMethodOTP] = useState(otpWhatsAppEnabled ? "whatsapp" : "sms" );
  const intl = useIntl();
  const classes = useStyles();


  useEffect(() => {
    setMethodOTP(otpWhatsAppEnabled ? "whatsapp" : "sms")
  },[otpWhatsAppEnabled])

  useEffect(()=>{

    const  pattern= /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape
    if(email){
        
       if(!pattern.test(email)){
        setEmailError(true)
       }else{
       setEmailError(false)}

    }

  },[email])

  useEffect(()=>{
    if(password){
        if(password.length >= 8) {
            setPasswordError(false)
        }
        else{
            setPasswordError(true)
        }
    }
},[password])


  const validationPhoneNumber = (number) => {

    const numberWithoutZero = number.startsWith("0") ? number.substring(1) : number
    const regex = /^[0-9\b]+$/;
    if (!regex.test(numberWithoutZero)) return null
    else return numberWithoutZero
  }

  const handleRequestOtp = async () => {
    
    const validNumber = validationPhoneNumber(phoneNumber)

    if (!validNumber) {
      setPhoneNumberError(true)
      return
    }
    onRequestOtp(`+${code.phone}` + validNumber, otpMethod);

  };

  const handleChangeNumber = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setPhoneNumber(e.target.value);
      setInitialPhoneNumber(e.target.value)
    }
  };

  const handleSelectCountry =(e,value)=>{
    if(!value) return
    setCode(value)
    setInitialCode(value)
  }

  const handleChangeOTPMethod = async (e) => {
    setMethodOTP(e.target.value)
  }


  return (
    <div style={{ width: '100%' }}>
      <div style={{display:"flex"}}>
            <Button
              onClick={()=>setTab(0)}
              size="large"
              style={{
                borderWidth:"0 0 4px 0",
                borderRadius: 0,
                borderColor: tab===0? "#FFA500":"#aaa",
                width: '50%',
                backgroundColor:"transparent",
                color:"black"
              }}>
              <FormattedMessage id="app.auth.phone"/>
            </Button>
            <Button
              onClick={()=>setTab(1)}
              size="large"
              style={{
                borderWidth:"0 0 4px 0",
                borderRadius: 0,
                borderColor: tab===1? "#FFA500":"#aaa",
                width: '50%',
                backgroundColor:"transparent",
                color:"black"
              }}>
              <FormattedMessage id= "app.auth.email"/>
            </Button>
      </div>

      {/* PHONE NUMBER */}
      {tab===0&&<div>
      <div style={{ width: '100%', display: 'flex', flexWrap:'wrap', marginBottom: 24 , marginTop: 24}}>
        
          <FormControl style={{ width: "100%", marginBottom: '1rem', display: 'none' }}>
            <Radio.Group onChange={handleChangeOTPMethod} value={otpMethod}>
              <Radio value={`whatsapp`}>Whatsapp</Radio>
              <Radio value={`sms`}>SMS</Radio>
             
            </Radio.Group>
          </FormControl>
          <FormControl style={{ width: "53%", marginRight: '2%' }}>
              <Autocomplete
                onChange={handleSelectCountry}
                style={{ width: "100%" }}
                options={countries}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                defaultValue={initialCode}
                getOptionLabel={option=> ("+"+option.phone+" "+option.label)}
                renderOption={(option) => (
                  <>
                    <span>{countryToFlag(option.code)}</span>
                    {option.label}   +{option.phone}
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a country"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', 
                    }}
                  />
                )}
              />
          </FormControl>
            <FormControl style={{ width: '45%' }}>
              <TextField
                variant="outlined"
                error={phoneNumberError}
                helperText={phoneNumberError && intl.formatMessage({ id: "app.auth.alarm.onlyNumber" })}
                id="standard-helperText"
                label={intl.formatMessage({ id: 'app.auth.phone' })}
                type="text"
                value={phoneNumber|| ""}
                onChange={handleChangeNumber}
              />
            </FormControl>
            </div>
            <div style={{ display: "flex", alignItems: "start", }}>
            <Checkbox style={{ display: "flex", marginRight: 12, marginTop: 4 }} checked={acceptPolicy} onChange={props.handleAcceptPolicy}/>
            <p style={{ textAlign: "start" }}><FormattedMessage id="app.auth.acceptPolicy" /> {<Link to="/privacy-policy"><FormattedMessage id="app.auth.privacyPolicy" /></Link>}</p>
          </div>
            <div className="loginTextField" style={{ textAlign: 'center' }}>
            <Button
              loading={loading}
              id="recaptcha-container"
              className="sign-in-button"
              type="primary"
              htmlType="submit"
              size="large"
              onClick={handleRequestOtp}
              style={{
                padding: '0 30px',
                borderRadius: 2,
                width: '100%',
              }}
              disabled={!(!!phoneNumber && props.acceptPolicy)}>
              &nbsp;<FormattedMessage id="app.auth.phone.RequestOTP"/>
            </Button>
          </div>
            
      </div>}

      {/* EMAIL */}
      {tab===1 && 
        <div>
          <div className="loginTextField" style={{ textAlign: 'left', marginBottom:"2%" }}>
            
            <div style={{position:"relative",marginTop:"4%"}}>
              <TextField  label={intl.formatMessage({id: "app.auth.email"})} variant="outlined"  style={{width:"100%" }} onChange={(e)=>setEmail(e.target.value)} disabled={props.methods.length>0} />
              <div style={{ position:"absolute", top: 0,bottom:0 , right: 2 , display:"flex", alignItems:"center" , marginRight:"2%"}}>
                {/* <Icon onClick={()=>{}} type="right-circle"  style={{fontSize:28} }/> */}
                 {/* <Icon type="loading" /> */}
                {email && emailError &&<Icon type="close-circle" style={{fontSize:24 , color:"red"}}/>}
                {email && !emailError && <Icon type="check-circle"style={{fontSize:24 , color:"green"}} />}
              </div>
            </div>
            {props.methods.length >=1 && props.methods.includes("password")  && <div>
                <div style={{position:"relative",marginTop:"4%" }}>
                    <TextField  label={intl.formatMessage({ id: "app.password"})} variant="outlined" type={showPassword?"text": "password"}  style={{width:"100%" }} onChange={(e)=>setPassword(e.target.value)}/>
                    <div style={{ position:"absolute", top: 0,bottom:0 , right:2, display:"flex", alignItems:"center" , marginRight:"2%"}}>
                        {/* <Icon type="loading" /> */}
                        {password && passwordError &&<Icon type="close-circle" style={{fontSize:24 , color:"red" ,marginRight:8}}/>}
                        {password && !passwordError && <Icon type="check-circle"style={{fontSize:24 , color:"green",marginRight:8}} />}
                        {showPassword ?<Icon type="eye" style={{fontSize:24}} onClick={()=> setShowPassword(false)}/>:<Icon type="eye-invisible" style={{fontSize:24}} onClick={()=> setShowPassword(true)}/>}

                    </div>
                </div>
                <div className="loginTextField" style={{ marginTop: "1%",width:"100%",textAlign:"right" }}>
                    <span>
                        <span className="aLink" onClick={()=>props.handleResetPassword(email)}>
                        <FormattedMessage id="app.forgotPassword" />
                        </span>
                    </span>
                </div>
                <div className="loginTextField" style={{ textAlign: 'center' }}>
                    <Button
                    loading={loading}
                    className="sign-in-button"
                    type="primary"
                    htmlType="submit"
                    size="large"
                    onClick={()=>props.handleSignInWithPassword(email, password)}
                    style={{
                        padding: '0 30px',
                        borderRadius: 2,
                        width: '100%'
                    }}
                    disabled={!(!!password && props.acceptPolicy && !passwordError)}
                    >
                    <FormattedMessage id="app.letStart"/>
                    </Button>
                </div>
                </div> }
                {props.methods.length >1 && <Divider><FormattedMessage id= "app.Or"/></Divider>}
                {props.methods.length >=1 && props.methods.includes("emailLink") &&<div className="loginTextField" style={{ textAlign: 'center' }}>
                    {/* <Button
                    loading={loading}
                    className="sign-in-button"
                    type="primary"
                    htmlType="submit"
                    size="large"
                    onClick={()=>props.handleSignInWithLink(email)}
                    style={{
                        padding: '0 30px',
                        borderRadius: 2,
                        width: '100%'
                    }}
                    disabled={!(!!email && props.acceptPolicy && !emailError)}
                    >
                    <FormattedMessage id="app.auth.loginWithLink"/>
                    </Button> */}
                </div>}
          </div>
          {!props.methods.length >=1 && <div style={{ display: "flex", alignItems: "start", }}>
            <Checkbox style={{ display: "flex", marginRight: 12, marginTop: 4 }} onChange={props.handleAcceptPolicy} />
            <p style={{ textAlign: "start" }}><FormattedMessage id="app.auth.acceptPolicy" /> {<Link to="/privacy-policy"><FormattedMessage id="app.auth.privacyPolicy" /></Link>}</p>
          </div>}
          {!props.methods.length >=1 && 
          <div className="loginTextField" style={{ textAlign: 'center' }}>
            <Button
              loading={loading}
              className="sign-in-button"
              type="primary"
              htmlType="submit"
              size="large"
              onClick={()=>props.handleGetUserByEmail(email)}
              style={{
                padding: '0 30px',
                borderRadius: 2,
                width: '100%'
              }}
              disabled={!(!!email && props.acceptPolicy && !emailError)}
              >
               <FormattedMessage id="app.letStart"/>
            </Button>
          </div>}
        </div>}
        {process.env.REACT_APP_PLATFORM ==="thai"&&<Divider>Or</Divider>}
        {/* FACEBOOK */}

        {process.env.REACT_APP_PLATFORM ==="thai" &&<div
            
            style={{
              
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginBottom: 24,
              marginTop: 16
            }}>
              
            <Icon
              onClick={handleFacebookLogin}
              style={{
                padding: 0,
                fontSize: 48,
                marginRight: 12,
                marginLeft: 12,
                color: '#3b5998 ',
                cursor :"pointer"
              }}
              type="facebook"
              theme="filled"
            />
            
        </div>}
    </div >
  );
}

export default compose(
  Form.create(),
  withApollo,
  withRouter // TODO: should put router only inside auth wrapper
)(LoginForm);
