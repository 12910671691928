import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '@material-ui/core';
import { Button } from "antd"
import { useIntl } from "react-intl"

export default function OtpForm({ phoneInfo, handleSubmitOtp, handleToChangeMethod ,handleBackToLoginForm ,loading }) {
  const [otp, setOtp] = useState([]);
  const inputRefs = useRef([]);
  const inputs = [];
  const intl = useIntl()

  const handleInToChangeMethod = (event, method) => {
    event.preventDefault();

    handleToChangeMethod(method)
  }


  useEffect(() => {
    if (!otp) return;
    if (otp.length === 6) {
      const nullValue = otp.find(el=> !el)
      if(nullValue >=0){
        return
      }
      const value = otp.toString().split(",").join("")
      
      handleSubmitOtp(value);
      
    }
  }, [otp]); // eslint-disable-line

  for (let index = 0; index < 6; index++) {
    inputs.push(
      <TextField
        type="tel"
        key={index}
        id="outlined-basic"
        variant="outlined"
        inputProps={{ maxLength: 1 }}
        style={{ width: '12%', textAlign: 'center' }}
        onKeyUp={(e) => {
          if(e.key==="Delete" || e.key==="Backspace"){
            const value = [...otp]
            value[index] = null
            setOtp(value)
            index <= 5 && inputRefs.current[index - 1].focus();
          }else if(e.key==="ArrowLeft"){
            index >0 && inputRefs.current[index - 1].focus();
          } else if(e.key ==="ArrowRight"){
            index < 5 && inputRefs.current[index + 1].focus();}
          else{
            const value = [...otp]
            value[index] = e.target.value
            setOtp(value)
          index < 5 && inputRefs.current[index + 1].focus();
          }

          //   if (index < 4) {
          //     inputRefs.current[index + 1].focus();
          //   } else {
          //     handleSubmitOtp();
          //   }
        }}
        autoFocus={index === 0 ? true : false}
        inputRef={(ref) => (inputRefs.current[index] = ref)}
      />
    );
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection:"column",
        alighItem: 'center'
      }}>
        {phoneInfo.method === "whatsapp" && <div style={{ width:"100%" , display:"flex" , justifyContent:"center", margin: '1em 0'}}>
          {intl.formatMessage({id:"app.otpSentToWhatsApp"})}
        </div>}
        <div style={{ width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        }}>
          {inputs}
        </div>
        {phoneInfo.method === "whatsapp" && 
        <div style={{ width:"100%" , display:"flex" , justifyContent:"center", marginTop: '2vh'}}>
          <Button size="small" type="link" className='type-link-button' onClick={(e) => handleInToChangeMethod(e, 'sms')} style={{ width:'100%' }}>
            {intl.formatMessage({id:"app.trySMS"})}
          </Button>
        </div>
        }
        <div style={{ width:"100%" , display:"flex" , justifyContent:"center"}}>
          <Button
            loading={loading}
            id="recaptcha-container"
            className="sign-in-button"
            type="primary"
            htmlType="submit"
            size="large"
            onClick={handleBackToLoginForm}
            style={{
              margin:"6%",
              borderRadius: 2,
              width: '44%'
            }}
            >
            {intl.formatMessage({id:"app.back"})}
          </Button>
        
        </div>
    </div>
  );
}
