import React, {useEffect} from 'react'
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { withRouter } from 'react-router'
import { compose } from 'recompose';


const AppUrlListener=(props)=> {
    const {history} = props

    useEffect(() => {
        const unsubscribe= App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
          
          const slug = event.url.split('.run').pop();
          if (slug) {
            history.push(slug);
          }
          // If no match, do nothing - let regular routing
          // logic take over
        });
        return ()=>unsubscribe
      }, []);// eslint-disable-line 


    return (
    <div>

    </div>
  )
}

export default compose(withRouter)(AppUrlListener)
// export default AppUrlListener
