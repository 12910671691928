import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AuthContext } from '../../../Context/AuthContext';
import { Spin, Modal, Result } from 'antd';
import LoginForm from './LoginForm';
import {  FormattedMessage, useIntl } from 'react-intl';
import OtpForm from './OtpForm';
import useAuth from '../../../Hook/useAuth';
// import { compose, withApollo  } from 'react-apollo';
import { compose } from "recompose"
import { withApollo } from "@apollo/client/react/hoc"
import withUser from '../../hocs/withUser';
import config from '../../../config';
import { Capacitor } from '@capacitor/core'
import platformObj from '~/config'

const LoginPage = ({ history,client, location, currentUser, updateUser }) => {
  const intl = useIntl();
  const { signInPhoneNumber , 
          confirmationOtp, 
          getUserByEmail, 
          logInWithEmailPassword, 
          sendSignInLink ,
          signInPhoneNumberNative,
          confirmationOtpNative , 
          facebookLogIn ,
          requestToResetPassword,
          confirmOTPWhatsappCustomFirebase
        } = useAuth();
  const { user, userIsLoading } = useContext(AuthContext);
  const [requestOpt, setRequestOtp] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [acceptPolicy, setAcceptPolicy] = useState(false)
  const [result , setResult] = useState(null)
  const [methods , setMethods] = useState([])
  // const [showEmailForm , setShowEmailForm] = useState(false)
  const isNative = Capacitor.isNativePlatform()
  const [loading, setLoading] = useState(false)
  const [initialPhoneNumber , setInitialPhoneNumber] = useState(null)
  const [initialCode , setInitialCode] = useState(config.defaultPhoneCode)
  const [loginPhoneInfo, setPhoneInfo] = useState({});

  useEffect(() => {
    
    if (user && userIsLoading) {
      // return history.push('/');
      setRequestOtp(false)
      setResult(null)
      // setShowEmailForm(true)
    }
    // if(currentUser && !currentUser.email) {
    //   setShowEmailForm(true)
    // }
    if(currentUser && currentUser.email){
      history.push(location.state?.from.pathname ||"/");
    }
    if (!user && userIsLoading) {
      return () => {
        return (
          <div className="modLoadding">
            <Spin tip="Loading..." size="large" />
          </div>
        );
      };
    }

    return ;
  }, []); // eslint-disable-line

 
  const onRequestOtp = async (phoneNumber, method) => {
    try {
      setPhoneInfo({ phoneNumber: phoneNumber, method: method });
      setLoading(true)
      let result 
      if(isNative) {
        result = await signInPhoneNumberNative(phoneNumber)
        // result = res.verificationId
      }
      else {
        result = await signInPhoneNumber(phoneNumber, method);
      }
      
      setRequestOtp(true);
      if(!result.success){
        setAcceptPolicy(false)
        setRequestOtp(false);
        setConfirmationResult(null);
        setLoading(false)
        return handleError(result.error);
      }
      
      setConfirmationResult(result);
      setLoading(false)

    } catch (error) {
      console.error(error);
      return handleError(error);
    }
  };


  const handleSubmitOtp = async (otp) => {
    try {
      setLoading(false)
      
      if(confirmationResult.method === "sms") { 
          if(isNative){
            const res = await confirmationOtpNative(confirmationResult.result , otp , confirmationResult.mode)

            if (!res.success) {
              setLoading(false)
              return handleError(res.error);
            }
            if (res.success){
              setLoading(false)
              history.push( location.state?.from.pathname || "/")
            }
          }
          else {
            const res = await confirmationOtp(confirmationResult.result, otp, confirmationResult.mode);
            if (!res.success) {
              setLoading(false)
              return handleError(res.error);
            }
            if (res.success){
              setLoading(false)
              history.push( location.state?.from.pathname || "/")
              window.location.reload()
            }
            
          }  
      } 

      if(confirmationResult.method === "whatsapp") {
          const res = await confirmOTPWhatsappCustomFirebase(confirmationResult.result, otp, confirmationResult.mode);
          if (!res.success) {
            setLoading(false)
            return handleError(res.error);
          }
          if (res.success){
            setLoading(false)
            history.push( location.state?.from.pathname || "/")
            window.location.reload()
          }
      }
      
      
    } catch (error) {
      return handleError(error);
    }
  };

  function handleError(error) {
    Modal.error({
      title: intl.formatMessage({ id: 'app.auth.loginFail' }),
      content: error
    });
  }

  const handleAcceptPolicy = (e) => {
    setAcceptPolicy(e.target.checked)
  }

  const handleGetUserByEmail= async(email)=>{
    setLoading(true)
    
    const res = await getUserByEmail({email , navigatePath: location.state?.from.pathname})
    
    if(res.success && res.methods !==undefined){
      setMethods(res.methods)
      setLoading(false)
      return
    }
    if(res.success){
      setResult(true)
      setLoading(false)
      return
    }
    if(!res.success){
      setResult(false)
      setLoading(false)
      return
    }    
  }

  const handleToChangeMethod = async(method) => {
    onRequestOtp(loginPhoneInfo.phoneNumber, method)
  }

  const handleSignInWithPassword =async(email, password) =>{
    setLoading(true)

    const res = await logInWithEmailPassword({email:email, password:password})
    if (res.error) {
      handleError(res.error);
      setLoading(false)

      return;
    }

    setLoading(false)
    history.push(location.state?.from.pathname ||"/")
   
  }
  const handleSignInWithLink =async(email)=>{
    setLoading(true)

    const url= `${process.env.REACT_APP_BASE_URI}/signin/`
    const res = await sendSignInLink({email,url , navigatePath:location.state?.from.pathname ||"/"} )
    if(res.success){
      setResult(true)
      setLoading(false)
      // setTimeout(()=>{history.push("/")},2000)
      return
    }
    if(!res.success){
      setResult(false)
      setLoading(false)
      // setTimeout(()=>{history.push("/login")},2000)
      return
    }  
  }

  const handleResetPassword = async (email)=>{
    /////THIS IS THE CASE THAT USER IS NOT LOGGED IN YET 
    // CHECK THE EXISTING OF USER BY EMAIL 
    //////IF EXIST SEND EMAIL VERIFY 
    //////IF NOT SEND 404 => HANDLE ERROR 
    
    const res = await requestToResetPassword({email, navigatePath:location.state?.from.pathname ||"/" })
    if(res.success){
      setResult(true)
      return
    }
    if(!res.success){
      setResult(false)
      return
    }  
  }
  // const handleSubmitEmail = async(email)=>{
  //   try{

  //      /////update email in api
  //      const success = await requestEmailMutation({variables:{email, userId : currentUser._id}})

  //       if(success){
  //       ///update email in firebase 
  //         await updateUserEmail(email)

  //         client
  //         .query({
  //           query: fetchUser,
  //           fetchPolicy: 'network-only'
  //         })
  //         .then(async() => {
  //           await client.reFetchObservableQueries();
  //           history.push(location.state?.from.pathname ||"/")
  //         })
  //       }
        
  //   }catch(error){
  //     console.error({error});
  //     let errorMessage = error
  //     if(error.message.includes("E11000")) errorMessage = "This email is existed please submit another email"
  //     handleError(errorMessage)
  //   }
  // }

  const handleFacebookLogin =async()=>{
    const res = await facebookLogIn();
    if (res.error) {
      handleError(res.error);
      return;
    }
    history.push(location.state?.from.pathname ||"/")
  }

  const handleBackToLoginForm =()=>{
    setRequestOtp(!requestOpt)
  }

  return (
    <div
      style={{
        width: 380,
        paddingLeft:8,
        paddingRight:8,
        height:"100hv",
        overflow:"hidden",
        animationDuration: `${1.2}s`,
        animationDelay: `${0}s`,
        display: 'flex',
        justifyContent:"center",
        flexDirection: 'column',
        alignItems: 'center',
        marginTop:"16%",
      }}
      >
      <div className="loginModalHeader" style={{ width: '100%' }}>
      {process.env.REACT_APP_PLATFORM !== 'maybank' &&
        <div style={{display:"flex" , alignItems:'center' , justifyContent:"space-around"}}>
          <img
            src="logorunner.png"
            style={{ width: '100%', maxWidth: '90px', marginBottom: 20 }}
            alt="logo"
          />
          <div style={{display:"flex"  ,flexDirection:"column", alignItems:"start" }}>
            <h1 style={{ fontWeight: '500' , fontSize:24, lineHeight:2 }} /*id="recaptcha-container"*/>
              <FormattedMessage id="app.welcomeTo"/>
              {/* {intl.formatMessage({ id: 'app.auth.login' })} */}
            </h1>
            <h1 style={{ fontWeight: 'bold' , fontSize:48 ,lineHeight:0 }}>{config.brandName}</h1>
          </div>
        </div>
        }
        {process.env.REACT_APP_PLATFORM === 'maybank' &&
          <img
          src={platformObj.logo.urlLogin}
          style={{ width: '100%', maxWidth: '300px', marginBottom: 20,  }}
          alt="logo"
        />
        }

        {!requestOpt && result===null && (<>
        {/* {!requestOpt && result===null && !showEmailForm && (<> */}
          <LoginForm 
            loading = { loading }
            onRequestOtp={onRequestOtp}
            handleError={handleError}
            acceptPolicy={acceptPolicy}
            handleAcceptPolicy={handleAcceptPolicy}
            handleGetUserByEmail={handleGetUserByEmail}
            methods={methods}
            handleSignInWithLink={handleSignInWithLink}
            handleSignInWithPassword={handleSignInWithPassword}
            handleFacebookLogin={handleFacebookLogin}
            handleResetPassword={handleResetPassword}
            initialPhoneNumber={initialPhoneNumber}
            setInitialPhoneNumber = {setInitialPhoneNumber}
            initialCode={ initialCode }
            setInitialCode = { setInitialCode }
          />
        </>
        )}
        {requestOpt && <OtpForm loading={ loading } phoneInfo={loginPhoneInfo} handleToChangeMethod={handleToChangeMethod} handleBackToLoginForm={handleBackToLoginForm} handleSubmitOtp={handleSubmitOtp} />}
        {/* {showEmailForm && !requestOpt && !result && <EmailForm handleSubmitEmail={handleSubmitEmail} />} */}
        {result && <Result
          status="success"
          title={intl.formatMessage({id:"app.auth.emailSent"})}
          subTitle={intl.formatMessage({id:"app.auth.emailSentDescription"})}
        />}
        {result===false && <Result
            status="error"
            title={intl.formatMessage({id:"app.auth.someThingWentWrong"})}
            subTitle={intl.formatMessage({id:"app.auth.pleaseTryAgain"})}
          />
          }
      </div>
      {/* { process.env.REACT_APP_PLATFORM === 'maybank' &&
        <div className="links" style={{display:"flex" ,flexDirection:"column", justifyItems:"start", position: 'fixed',width: '100', bottom: '0'}}>
          <h1 style={{ fontWeight: '400' , fontSize:12, lineHeight:2}}>
            <a className="outsideLink" style={{ color : 'black'}} href='https://www.thai.run/'>powered by ThaiRun</a>
          </h1>
        </div>
      } */}
      
    </div>
  );
};

export default compose(
  withApollo, 
  withRouter , 
  withUser({ refetch: false }),
)(LoginPage);
