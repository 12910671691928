import fetch from 'isomorphic-fetch';
import config from '~/config'

const requestResetPassword =(email ,actionCode, language)=>
  fetch(`${process.env.REACT_APP_API_AUTH_URI}/resetpassword/requestion`, {
  method: 'POST',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'X-RunX-Platform': `${process.env.REACT_APP_PLATFORM}`,
    'platform': `${process.env.REACT_APP_PLATFORM}`,
    'brandName': `${config.brandName}`
  },
  body: JSON.stringify({email, actionCode , language})
}).then((res) => res.json());

const sendEmailLink =(email ,actionCode, language)=>
  fetch(`${process.env.REACT_APP_API_AUTH_URI}/emaillink`, {
  method: 'POST',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'X-RunX-Platform': `${process.env.REACT_APP_PLATFORM}`,
    'platform':`${process.env.REACT_APP_PLATFORM}`,
    'brandName':`${config.brandName}`
  },
  body: JSON.stringify({email,actionCode , language})
}).then((res) => res.json());

const queryUserByUid = (uid) =>
  fetch(`${process.env.REACT_APP_API_AUTH_URI}/queryuser`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-RunX-Platform': `${process.env.REACT_APP_PLATFORM}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(uid)
  }).then((res) => res.json());


const findUserByEmail = (email) =>
  fetch(`${process.env.REACT_APP_API_AUTH_URI}/user`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-RunX-Platform': `${process.env.REACT_APP_PLATFORM}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(email)
  }).then((res) => res.json());

const findUserByPhoneNumber = (phoneNumber) =>
  fetch(`${process.env.REACT_APP_API_AUTH_URI}/user`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-RunX-Platform': `${process.env.REACT_APP_PLATFORM}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(phoneNumber)
  }).then((res) => res.json());

const signUpUser = (user) =>
  fetch(`${process.env.REACT_APP_API_AUTH_URI}/signup`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      "X-RunX-Platform" :`${process.env.REACT_APP_PLATFORM}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(user)
  }).then((res) => res.json());
const signInUser = (user) =>
  fetch(`${process.env.REACT_APP_API_AUTH_URI}/signin`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-RunX-Platform': `${process.env.REACT_APP_PLATFORM}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(user)
  }).then((res) => res.json());

const signInByPopUpProvider = (user) =>
  fetch(`${process.env.REACT_APP_API_AUTH_URI}/signinbypopup`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-RunX-Platform': `${process.env.REACT_APP_PLATFORM}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(user)
  }).then((res) => res.json());

const registerUser = (user) =>
  fetch(`${process.env.REACT_APP_API_AUTH_URI}/register`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-RunX-Platform': `${process.env.REACT_APP_PLATFORM}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(user)
  }).then((res) => res.json());

const loginUser = ({ email, password }) =>
  fetch(`${process.env.REACT_APP_API_AUTH_URI}/login`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-RunX-Platform': `${process.env.REACT_APP_PLATFORM}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, password })
  }).then((res) => res.json());

const logoutUser = () =>
  fetch(`${process.env.REACT_APP_API_AUTH_URI}/logout`, {
    credentials: 'include'
  }).then((res) => res.json());

const getAuthUri = (strategy, { redirectTo = '' } = {}) => {
  let uri = `${process.env.REACT_APP_API_AUTH_URI}/${strategy}`;
  if (redirectTo) {
    uri += `?redirectTo=${redirectTo}`;
  }
  return uri;
};

const auth = {
  registerUser,
  loginUser,
  logoutUser,
  getAuthUri,
  signUpUser,
  signInUser,
  signInByPopUpProvider
};

  // export default auth
  // export default { registerUser }
  export {
    registerUser,
    loginUser,
    logoutUser,
    getAuthUri,
    signUpUser,
    signInUser,
    signInByPopUpProvider,
    findUserByPhoneNumber,
    findUserByEmail,
    queryUserByUid,
    sendEmailLink,
    requestResetPassword
  };
export default auth;
