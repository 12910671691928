import React, { useEffect, useState } from 'react'
import { Icon, Button , Modal } from 'antd';
import { TextField} from '@material-ui/core';
import useAuth from '../../../Hook/useAuth'
import { compose } from 'recompose';
import { withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';

import { FormattedMessage, useIntl } from 'react-intl';
// import config from '../../../config';

const ResetPasswordPage = function (props) {

    const intl = useIntl()
    
    const { history} = props
    const [password , setPassword] = useState(null)
    const [passwordError , setPasswordError] = useState(null)
    const [showPassword, setShowPassword ] = useState(false)
    const { resetPassword} = useAuth()
    const [actionCode , setActionCode] = useState(null)
    const [continueUrl , setContinueUrl] = useState(null)
    const [ loading , setLoading ] = useState(false)


    useEffect(()=>{

        const searchParams = new URLSearchParams(props.location.search)
        const actionCodeParam = searchParams.get("oobCode")
        const continueParam = searchParams.get("continueUrl")
        actionCodeParam && setActionCode(actionCodeParam)
        continueParam && setContinueUrl(continueParam)
    },[]) // eslint-disable-line

    useEffect(()=>{
        if(password){
            if(password.length >= 8) {
                setPasswordError(false)
            }
            else{
                setPasswordError(true)
            }
        }
    },[password])


    const handleSubmit = async()=>{
        setLoading(true)
        const res = await resetPassword(actionCode ,continueUrl, "en" , password) 
        
        if(res.success) {
            setLoading(false)
            history.push(res.path.split(`${process.env.REACT_APP_BASE_URI}`)[1]||"/")
        }
        if(res.error){
            setLoading(false)
            handleError(res.error,res.path.split(`${process.env.REACT_APP_BASE_URI}`)[1] )
        }
    }

    function handleError(error , path) {
        console.error(error);
        Modal.error({
          title: intl.formatMessage({ id: 'app.auth.loginFail' }),
          content: error,
          onOk() {history.push(path||"/")},
        });
    }
  return (
    <div
      style={{
        width: 380,
        animationDuration: `${1.2}s`,
        animationDelay: `${0}s`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop:"16%",
        padding: 20,
        
      }}>
        <div style={{ width: '100%' }}>

            <div>
            <p style={{ fontWeight: 'semiBold' , fontSize: 20,lineHeight:0 }}>Please submit your new password</p>
                <div style={{position:"relative",marginTop:"4%" }}>
                    <TextField  label={intl.formatMessage({ id: "app.password"})} variant="outlined" type={showPassword?"text": "password"}  style={{width:"100%" }} onChange={(e)=>setPassword(e.target.value)}   />
                    <div style={{ position:"absolute", top: 0,bottom:0 , right:2, display:"flex", alignItems:"center" , marginRight:"2%"}}>
                        {password && passwordError &&<Icon type="close-circle" style={{fontSize:24 , color:"red" ,marginRight:8}}/>}
                        {password && !passwordError && <Icon type="check-circle"style={{fontSize:24 , color:"green",marginRight:8}} />}
                        {showPassword ?<Icon type="eye" style={{fontSize:24}} onClick={()=> setShowPassword(false)}/>:<Icon type="eye-invisible" style={{fontSize:24}} onClick={()=> setShowPassword(true)}/>}

                    </div>
                </div>
                {passwordError&& <div><FormattedMessage id="app.auth.passwordAtleast"/></div>}
                
            </div>
        

            <div className="loginTextField" style={{ textAlign: 'center' }}>
                    <Button
                    loading = { loading }
                    className="sign-in-button"
                    type="primary"
                    htmlType="submit"
                    size="large"
                    onClick={handleSubmit}
                    style={{
                        padding: '0 30px',
                        borderRadius: 2,
                        width: '100%'
                    }}
                    disabled={!password || passwordError}
                    >
                    <FormattedMessage id="app.auth.submit"/>
                    </Button>
            </div>
        </div>
    </div>
  )
}

export default compose(
    withApollo,
    withRouter
)(ResetPasswordPage)