import { useContext } from 'react';
import { firebaseAuth } from './../Services/firebaseConfig';
import {
  createUserWithEmailAndPassword,
  RecaptchaVerifier,
  updateProfile,
  signInWithPhoneNumber,
  fetchSignInMethodsForEmail,
  updateEmail,
  verifyPasswordResetCode, 
  confirmPasswordReset ,
  reauthenticateWithCredential,
  EmailAuthProvider,
  signInWithCustomToken,
} from 'firebase/auth';
import { AuthContext } from '../Context/AuthContext';
import {
  signUpUser,
  logoutUser,
  signInUser,
  findUserByPhoneNumber,
  findUserByEmail,
  queryUserByUid,
  sendEmailLink,
  requestResetPassword
} from '../auth';
import { useIntl } from 'react-intl';
import { FirebaseAuthentication  } from '@capacitor-firebase/authentication';

import _ from 'lodash';

import createCustomUIDFirebaseMutation from '~/App/graphql/mutations/createCustomUIDFirebase.gql'
import loginOTPWhatsappMutation from '~/App/graphql/mutations/loginOTPWhatsapp.gql'
import confirmOTPWhatsappMutation from '~/App/graphql/mutations/confirmOTPWhatsapp.gql'

import { useMutation } from '@apollo/client';

export default function useAuth() {
  const { updateUser , user} = useContext( AuthContext );
  const intl = useIntl();
  const [createCustomUIDFirebase] = useMutation(createCustomUIDFirebaseMutation);
  const [loginOTPWhatsapp] = useMutation(loginOTPWhatsappMutation);
  const [confirmOTPWhatsapp] = useMutation(confirmOTPWhatsappMutation);


  const signUpWithEmailPassword = async (userProfile) => {
    const { password } = userProfile;
    const userEmail = userProfile.email;
    try {
      //////CREATE USER IN FIREBASE AUTH
      const userCredential = await createUserWithEmailAndPassword(
        firebaseAuth,
        userEmail,
        password
      );
      const { uid, email } = userCredential.user;

      await signUpUser({ uid, email });

      // //////UPDATE USER IN CONTEXT
      await updateUser({ uid, email });
      return { success: true, error: null };
    } catch (error) {
      console.error(error);
      return errorAuth(error.code || error, 'signUpWithEmailPassword');
    }
  };

  const logInWithEmailPassword = async ({ email, password }) => {
    try {
      /////////SIGNIN IN FIREBASE
      const result = await FirebaseAuthentication.signInWithEmailAndPassword({
        email , password
      })
      // console.log(result);
      // const userCredential = await signInWithEmailAndPassword(
      //   firebaseAuth,
      //   email,
      //   password
      // );
      /////////SIGNIN IN RACE-API
      const user = await signInUser({
        email,
        uid:result.user.uid
        // uid: userCredential.user.uid
      });
      ////update context
      updateUser(user.data);
      return { success: true, error: null };
    } catch (error) {
      console.error(error);
      return errorAuth(error.code, 'logInWithEmailPassword');
    }
  };

  const logOut = async () => {
    
    ///////UPDATE CONTEXT
    try {
      updateUser(null);

      ///////SIGNOUT IN FIREBASE
      // await signOut(firebaseAuth)

      await FirebaseAuthentication.signOut();
      // console.log('Sign-out successful.');

      await logoutUser();
    } catch (error) {
      return errorAuth(error.code, 'logOut');
    }

    
  };

  const reCatpChaVerify = () => {
     return new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      },
      firebaseAuth
    );

  };
  const signInPhoneNumberNative = async(phoneNumber) => {
    try {
      const res = await findUserByPhoneNumber({ phoneNumber });
      const result = await FirebaseAuthentication.signInWithPhoneNumber(
      {
        phoneNumber
      },
    );
    return {success :true , result : result.verificationId , mode : res.user? "signin" : "signup"}
    }catch(error){
      console.error(error);
      return errorAuth(error.code|| error , 'signInPhoneNumberNative')
    }
  }


  const signInPhoneNumber = async (phoneNumber, method) => {
    
    try {

      const res = await findUserByPhoneNumber({ phoneNumber: phoneNumber });

      if(method === 'whatsapp') {

        const getUIDFirebase = await createCustomUIDFirebase({ variables: { telephone: phoneNumber }});
        const uid = _.get(getUIDFirebase, 'data.createCustomUIDFirebase');
        
        const checkUserExist = await signInUser({
          uid,
          phoneNumber: phoneNumber
        });

        if(!res.user || _.get(checkUserExist, 'data') === null) {
            await signUpUser({
              uid,
              profile: { telephone: phoneNumber }
            }).then((res) => {
              console.log(res)
              if (res.error) {
                console.error(res.error);
              }
            });
        }

        const getOTP = await loginOTPWhatsapp({ variables: { telephone: phoneNumber }});
        
        if(_.get(getOTP, 'data.loginOTPWhatsapp') === false) {
          throw new Error("auth/otp-whatsapp-error")
        }

        return {
          success: true,
          result: {
            uid: uid
          },
          mode: "signin",
          method
        }
        
      }

      if(method === 'sms') {
          return {
              success: true,
              result: await signInWithPhoneNumber(firebaseAuth, phoneNumber, reCatpChaVerify()), 
              mode: res.user? "signin" : "signup",
              method
          }
      }
      
    } catch(error) {
      console.error(error);
      return errorAuth(error.code ? error.code : error.message, 'signInPhoneNumber');

    }
   
  };

  const confirmationOtpNative =async(verificationId , verificationCode , mode)=>{
    try{
          const result = await FirebaseAuthentication.signInWithPhoneNumber({
            verificationId,
            verificationCode,
          });
          const { uid, email, displayName, phoneNumber } = result.user;
      
          if (mode === 'signup') {
            //////SIGNIN / SIGNUP IN RACE API
            await signUpUser({
              uid,
              profile: { telephone: phoneNumber }
            }).then((res) => {
              if (res.error) {
                console.error(res.error);
              }
            });
          }
          if (mode === 'signin') {
            //////SIGNIN / SIGNUP IN RACE API
            await signInUser({
              uid,
              phoneNumber
            });
          }
          await updateUser({ uid, email, displayName, phoneNumber });
          return { success: true, error: null };
        } catch (error) {
          console.error({ error });
          return errorAuth(error.code, 'confirmatonResult');
        }
  }

  const confirmationOtp = async (confirmationResult, code, mode) => {
    try {
      /////////SIGNIN IN FIREBASE
      const result = await confirmationResult.confirm(code);
      const { uid, email, displayName, phoneNumber } = result.user;
      
      if (mode === 'signup') {
        //////SIGNIN / SIGNUP IN RACE API
        await signUpUser({
          uid,
          profile: { telephone: phoneNumber }
        }).then((res) => {
          if (res.error) {
            console.error(res.error);
          }
        });
      }
      if (mode === 'signin') {
        //////SIGNIN / SIGNUP IN RACE API
        await signInUser({
          uid,
          phoneNumber
        });
      }
      await updateUser({ uid, email, displayName, phoneNumber });
      return { success: true, error: null };
    } catch (error) {
      console.error({ error });
      return errorAuth(error.code, 'confirmatonResult');
    }
  };

  const confirmOTPWhatsappCustomFirebase = async (confirmationResult, code, mode) => {
      try {

        const res = await confirmOTPWhatsapp({variables: { uid: confirmationResult.uid, otp_input: code }});

        
        console.log("Confirm", res)
        if(_.get(res, 'data.confirmOTPWhatsapp') !== null) {
          const result = await signInWithCustomToken(firebaseAuth, _.get(res, 'data.confirmOTPWhatsapp'));
          const { uid, email, displayName, phoneNumber } = result.user;
          await signInUser({
            uid,
            phoneNumber
          });
          await updateUser({ uid, email, displayName, phoneNumber });
          return { success: true, error: null };
        } else {
          throw new Error("auth/otp-whatsapp-invalid")
        }
      } catch (error) {
        return errorAuth(error.message, 'confirmOTPWhatsappCustomFirebase');
      }
  }

  

  const updateUserProfile = async (data) => {
    //// Firebase updateProfile
    //// data would be  displayName , email , photoURL
    await updateProfile(firebaseAuth.currentUser, { ...data })
      .then(() => {
        firebaseAuth.currentUser.reload();
        return { success: true, error: null };
      })
      .catch((error) => {
        return errorAuth(error.code, 'updateUserProfile');
      });
    // firebaseAuth.currentUser
    // await updateUser(userCredential.user.uid);
  };

  const getUserByEmail = async({email, navigatePath})=>{
    try{
      
      const res = await findUserByEmail({email:email})
      const {success, user , error} = res;
      ///if no user send  link to email for signinwithemail
      if(success && !user && error ==='auth/user-not-found' ){
        return await sendSignInLink({email,url: `${process.env.REACT_APP_BASE_URI}/registeration/`, navigatePath})
      }

      ///if user check provider if === password => ask password=> signin with password
      ///if no password send link to email for signinwithlink 
      if(success && user ){
        return await fetchSignInMethodsEmail({email, navigatePath})
      }
    }catch(error){
      ////no need to handle because this function is just to find user and make the action direction
      console.error(error)
    }
  }

  const fetchSignInMethodsEmail =async({email,navigatePath, language})=>{
    try{

      const signInMethods = await fetchSignInMethodsForEmail(firebaseAuth, email);

      console.log(signInMethods)
      //// email link and password
      if(signInMethods.length >1){
        return {success :true , methods : signInMethods}
      }
      ////email link 
      if(signInMethods.length ===1){
        return  await sendSignInLink({email ,url: `${process.env.REACT_APP_BASE_URI}/signin/`, navigatePath, language})
      }
      
    }
    catch(error){
      console.error(error);
      return errorAuth(error.code)
    };

  }


  const sendSignInLink = async({email,navigatePath=null, url})=>{
    try{
      // const token = jwt.sign({email, navigatePath} , "NUR.IAHT");
      const token = btoa(JSON.stringify({email, navigatePath}))
      const actionCodeSetting = {url: url+token , handleCodeInApp:true}
      const sendEmail = await sendEmailLink(email,actionCodeSetting,intl.locale )
      if(sendEmail.success){
        return {success:true , error :null}
      }else{
        return {success:false , error:sendEmail.error}
      }
    }catch(error){
      console.error({...error});
      return errorAuth(error.code)
    }
  }

  const signInWithEmail =async(email , emailLink , password , mode="signin" )=>{
    try{
      // let user
      const { isSignInWithEmailLink } = await FirebaseAuthentication.isSignInWithEmailLink({emailLink});
      
        if(isSignInWithEmailLink){

        const { user } =await FirebaseAuthentication.signInWithEmailLink({email, emailLink})
        
        if(user){
          
          const { uid, email, displayName, phoneNumber } = user
          if(mode==="signup"){
            await signUpUser({ uid, email , password });
          }
          updateUser({ uid, email, displayName, phoneNumber });
          return {success:true , user}
        }
      }

    }catch(error){
      console.error(error);
      return errorAuth(error.code , "signInWithEmail")
    }
  }

  const updateUserPassword= async( newPassword)=>{
    try{
      // await updatePassword(firebaseAuth.currentUser, newPassword)
      
      const {user}= await FirebaseAuthentication.getCurrentUser();
      if(!user) return
      await FirebaseAuthentication.updatePassword({newPassword});

      return {success:true}
    }catch(error){
      console.error(error);
      return errorAuth(error.code)
    };
  }

  const updateUserEmail= async(email)=>{
    try{
      await updateEmail(firebaseAuth.currentUser, email)
      return {success:true}
    }catch(error){
      console.error(error);
      return errorAuth(error.code)
      // return {success:false}
    };
  }

  const getUserByUid = async(uid)=>{
    try{
      
      const res = await queryUserByUid(uid)
      const {success, user } = res;
      ///if no user send  link to email for signinwithemail
      // if(success && !user && error ==='auth/user-not-found' ){
      //   return await sendSignInLink({email,url: `${process.env.REACT_APP_BASE_URI}/registeration/`, navigatePath})
      // }

      ///if user check provider if === password => ask password=> signin with password
      // ///if no password send link to email for signinwithlink 
      if(success && user ){
        return success
      }
      
    }catch(error){
      ////no need to handle because this function is just to find user and make the action direction
      console.error(error)
    }
  }


  const facebookLogIn =async(navigatePath)=>{
   
    try{

      const result = await FirebaseAuthentication.signInWithFacebook()
      const user = result.user
      const {uid, displayName,email} = result.user
      const firstName =  displayName?.split(" ")[0]
      const lastName = displayName?.split(" ")[1]
      
      /////get user by uid in mongodb
      const findUser = await getUserByUid({uid})
      if(!findUser){
        await signUpUser({
          uid,
          email,
          profile: { email , firstName , lastName }
        }).then((res) => {
          if (res.error) {
            console.error(res.error);
          }
        });
      }

      if(findUser){
        const { uid, email, displayName, phoneNumber } = user
        updateUser({ uid, email, displayName, phoneNumber });
        return {success:true , user}
      }

    }catch(error){
      console.error(error);
      return errorAuth(error.code, "facebookLogIn")
    }
  }

  const requestToResetPassword =async({email , navigatePath})=>{
    try{
      const url = `${process.env.REACT_APP_BASE_URI}`+navigatePath
      const actionCodeSettings = {url,handleCodeInApp:true}

      const res = await requestResetPassword(email , actionCodeSettings ,intl.locale)
      if(res.success){
        return {success:true , error :null}
      }else{
        return {success:false , error:res.error}
      }
    }catch(error){
      console.error(error);
      return errorAuth(error.code , "requestToResetPassword")
    }

  }

  const resetPassword = async(actionCode, continueUrl, lang , newPassword)=>{
   
    return verifyPasswordResetCode(firebaseAuth, actionCode).then((email) => {
      return confirmPasswordReset(firebaseAuth, actionCode, newPassword).then( async () => {
        // Password reset has been confirmed and new password updated.
        return { ...await logInWithEmailPassword({email , password:newPassword}), path:continueUrl}
      }).catch((error) => {
        console.log(error);
        return {...errorAuth(error,"resetPassword"), path:continueUrl}
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
      });
    }).catch((error) => {
      console.error(error);
      return {...errorAuth(error,"resetPassword"),path:continueUrl}
      // Invalid or expired action code. Ask user to try to reset the password
      // again.
    });
  }

  const reAuthentication = async(password)=>{
    // console.log(password , user.email);
    try{
      const credential = await EmailAuthProvider.credential(user.email, password);
      return await reauthenticateWithCredential(firebaseAuth.currentUser , credential).then(() => {
        // User re-authenticated.
        return {success:true ,error :null}
      }).catch((error) => {
        // An error ocurred
        console.error(error);
        return {success:false , error}
      });
    }catch(error){
      console.error(error);
      return {success:false , error}
    }

  }


  const errorAuth = (error, process) => {
    console.log("Error")
    console.log(error)
    console.log("----")

    console.error(error, process);
    let errorMessage = intl.formatMessage({ id: 'app.auth.somethingwrong' });
    switch (error) {
      //////firebase
      // signupwith email password
      case 'auth/invalid-email':
        errorMessage = intl.formatMessage({ id: 'app.auth.invalidEmail' });
        break;
      case 'auth/user-disabled':
        errorMessage = intl.formatMessage({ id: 'app.auth.userDisabled' });
        break;
      case 'auth/user-not-found':
        errorMessage = intl.formatMessage({ id: 'app.auth.userNotFound' });
        break;
      case 'auth/wrong-password':
        errorMessage = intl.formatMessage({ id: 'app.auth.wrongPassword' });
        break;
      // signInWithPopup
      case 'auth/account-exists-with-different-credential':
        errorMessage = intl.formatMessage({
          id: 'app.auth.popup.accountExistsWithDiffCredential'
        });
        break;
      case 'auth/auth-domain-config-required':
        errorMessage = intl.formatMessage({
          id: 'app.auth.popup.domainConfig'
        });
        break;
      case 'auth/cancelled-popup-request':
        errorMessage = intl.formatMessage({
          id: 'app.auth.popup.cancelled.popup'
        });
        break;
      case 'auth/operation-not-allowed':
        errorMessage = intl.formatMessage({
          id: 'app.auth.popup.operationNotAllow'
        });
        break;
      case 'auth/operation-not-supported-in-this-environment':
        errorMessage = intl.formatMessage({
          id: 'app.auth.popup.operationNotSupportEnv'
        });
        break;
      case 'auth/popup-blocked':
        errorMessage = intl.formatMessage({ id: 'app.auth.popup.blocked' });
        break;
      case 'auth/popup-closed-by-user':
        errorMessage = intl.formatMessage({ id: 'app.auth.popup.closeByUser' });
        break;
      case 'auth/unauthorized-domain':
        errorMessage = intl.formatMessage({
          id: 'app.auth.popup.unauthorizedDomain'
        });
        break;
      case 'auth/captcha-check-failed':
        errorMessage = intl.formatMessage({
          id: 'app.auth.phone.captchaCheckFailed'
        });
        break;
      case 'auth/invalid-phone-number':
        errorMessage = intl.formatMessage({
          id: 'app.auth.phone.invalidPhoneNumber'
        });
        break;
      case 'auth/missing-phone-number':
        errorMessage = intl.formatMessage({
          id: 'app.auth.phone.missingPhoneNumber'
        });
        break;
      case 'auth/quota-exceeded':
        errorMessage = intl.formatMessage({
          id: 'app.auth.phone.quotaExceeded'
        });
        break;
      //////confirmationResult
      case 'auth/invalid-verification-code':
        errorMessage = intl.formatMessage({ id: 'app.auth.phone.invalidCode' });
        break;
      case 'auth/missing-verification-code':
        errorMessage = intl.formatMessage({ id: 'app.auth.phone.missingCode' });
        break;

      ////// createuserwithemailandpassword
      case 'auth/email-already-in-use':
        errorMessage = intl.formatMessage({
          id: 'app.auth.email.alreadyInUse'
        });
        break;
      case 'auth/weak-password':
        errorMessage = intl.formatMessage({ id: 'app.auth.passwordWeak' });
        break;
      case 'auth/invalid-credential-or-provider-id':
        errorMessage = intl.formatMessage({
          id: 'app.auth.popup.invalidProviderId'
        });
        break;
      case 'Account is exist , please signin':
        errorMessage = intl.formatMessage({
          id: 'app.auth.accountIsExist'
        });
        break;
      case 'Account is not exist , please signup':
        errorMessage = intl.formatMessage({
          id: 'app.auth.accountIsNotExist'
        });
        break;
      case 'auth/too-many-requests':
        errorMessage = intl.formatMessage({
          id: "app.auth.tooManyRequest"
        });
        break;
      case "auth/invalid-action-code":
        errorMessage= intl.formatMessage({
          id:"app.auth.invalidActionCode"
        });
        break;
      case "auth/expired-action-code":
      errorMessage= intl.formatMessage({
        id:"app.auth.expiredActionCode"
      });
      break
      case "auth/requires-recent-login":
        errorMessage= intl.formatMessage({
          id:"app.auth.requiresRecentLogin"
        });
        break;
      case "auth/otp-whatsapp-error":
        errorMessage = "OTP Error Whatsapp";
        break;
      case "auth/otp-whatsapp-invalid":
        errorMessage = "OTP Invalid";
        break;
      default:
        break;
    }

    return { success: false, error: errorMessage };
  };

  return {
    signUpWithEmailPassword,
    logOut,
    logInWithEmailPassword,
    facebookLogIn,
    reCatpChaVerify,
    signInPhoneNumber,
    confirmationOtp,
    updateUserProfile,
    getUserByEmail,
    signInWithEmail,
    updateUserPassword,
    sendSignInLink,
    updateUserEmail,
    signInPhoneNumberNative,
    confirmationOtpNative,
    requestToResetPassword,
    resetPassword,
    reAuthentication,
    confirmOTPWhatsappCustomFirebase
  };
}
